:root {
    --app-height: calc(var(--app-vh, 1vh) * 100);
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
    --header: calc(64px + var(--sat));
}

*,
::after,
::before {
    box-sizing: border-box;
}

:focus-visible {
    outline: none;
}

html,
body {
    overscroll-behavior: none;
    margin: 0;
    overflow: hidden;
}

body {
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans', sans-serif;
}

input,
textarea {
    font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
p,
pre,
ol,
ul,
li {
    margin: 0;
}

button,
input,
textarea {
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    resize: none;
    background: none;
    color: black;
}
